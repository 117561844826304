const SYSTEM_ENUM = {
  WHY: [
    {
      title: '',
      items:
        [
          'No origination fees',
          'No prepayment penalty',
          'No hidden fee',
          'Easy online application',
          'Instant decision',
          'Same/Next business day funding'
        ]
    }
  ],
  DECISION_LOGIC_ICON: {
    PERSONAL: {
      title: 'IDENTITY',
      color: 'grey',
      class: 'mdi-dots-horizontal-circle-outline'
    },
    EMPLOYMENT: {
      title: 'EMPLOYMENT',
      color: 'grey',
      class: 'mdi-dots-horizontal-circle-outline'
    },
    BANK: {
      title: 'BANK ACCOUNT',
      color: 'grey',
      class: 'mdi-dots-horizontal-circle-outline'
    }
  },
  IGNORE_GET_ACCOUNT_REQUEST_URL: [
    '/settings/error',
    '/sign-in',
    '/500',
    '/403',
    '/404',
    'create-customer',
    '/create-account',
    '/reset-password',
    '/welcome',
    '/17E646E2A8074596B8D92B70C767BA7E',
    '/non-existent-account'
  ],
  RULES: {
    FIRST_NAME: 'required|alphabetWithSpace|noSpaceAtStartAndEnd|noContinuousSpace|max:35',
    MIDDLE_NAME: 'alphabetWithSpace|noSpaceAtStartAndEnd|noContinuousSpace|max:35',
    LAST_NAME: 'required|alphabetWithSpace|noSpaceAtStartAndEnd|noContinuousSpace|max:35',
    SSN: 'required|ssn',
    REQUIRED: 'required',
    STREET_ADDRESS: 'required|noSpaceAtStartAndEnd|noContinuousSpace|max:40',
    OPTIONAL_ADDRESS: 'noSpaceAtStartAndEnd|noContinuousSpace|max:50',
    CITY: 'required|noSpaceAtStartAndEnd|noContinuousSpace|max:17',
    ZIP: 'required|zip',
    PHONE: 'phone',
    CELL_PHONE: 'required|phone',
    EMAIL: 'required|email',
    ALTERNATIVE_EMAIL: 'email',
    EMPLOYER_NAME: 'required|noSpaceAtStartAndEnd|noContinuousSpace',
    EMPLOYER_EMAIL: 'email',
    EMPLOYER_PHONE: 'required|phone',
    EMPLOYER_ZIP: 'zip',
    EMPLOYER_CITY: 'noSpaceAtStartAndEnd|noContinuousSpace|max:17',
    EMPLOYER_ADDRESS: 'noSpaceAtStartAndEnd|noContinuousSpace|max:50',
    JOB_TITLE: 'required|noSpaceAtStartAndEnd|noContinuousSpace|max:50',
    BANK_ROUTING_NO: 'required|bankRoutingNo',
    BANK_ACCOUNT_NO: 'required|bankAccountNo|max:17',
    BANK_NAME: 'required|noSpaceAtStartAndEnd|noContinuousSpace|max:30'
  }
}
export default SYSTEM_ENUM
